



















































































































import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import fuzzysort from 'fuzzysort';

@Component
export default class Dropdown extends Vue {
  @Model('change', { type: Array }) readonly dropdownValues!: any
  @Prop({ type: Array, default: [] }) options!: any;
  @Prop({ type: Boolean, default: false }) multiselect!: boolean;
  @Prop({ type: Array, default: [] }) selectionLocation!: any;
  @Prop({ type: String, default: '' }) trackBy!: string;
  @Prop({ type: String, default: '' }) label!: string;

	searchDropdownInput = ''
//   @Prop({ required: true }) stepItem!: any;


  created() {

  }

  isSelected(option) {
	  for (var x = 0; x < this.dropdownValues.length; x++) {
		  if (this.trackBy != '') {
			if (option.text == this.dropdownValues[x].text) {
				return true
			}
		  } else {
			if (option == this.dropdownValues[x]) {
				return true
			}
		  }
	  }
	  return false
  }


  get filteredOptions() {
	  if (this.searchDropdownInput.length == 0) {
		  return this.options
	  }
	  var fuzzysort_options: any[] = []
	  if (this.trackBy != '') {
		for (var x = 0; x < this.options.length; x++) {
			fuzzysort_options.push(this.options[x].text)
		}
	  } else {
		  fuzzysort_options = JSON.parse(JSON.stringify(this.options))
	  }
		var results: any = []
		const fuzzysort_results = fuzzysort.go(this.searchDropdownInput, fuzzysort_options)
		
		for (var x = 0; x < fuzzysort_results.length; x++) {
			if (this.trackBy != '') {
				for (var y = 0; y < this.options.length; y++) {
					if (this.options[y].text == fuzzysort_results[x].target) {
						results.push(this.options[y])
					}
				}
			} else {
				// @ts-ignore
				results.push(fuzzysort_results[x].target)
			}
		}
	  return results
  }



  hideDropdown() {
	  this.$emit('hide-dropdown')
  }

  addValToDropdown(val) {
	  var did_emit = false
	  if (val.disabled != null && val.disabled) {
		  return 
	  }
	  if (!this.multiselect) {
		  while (this.dropdownValues.length) { this.dropdownValues.pop(); }
		  this.dropdownValues.push(val)
		  did_emit = true
		  this.$emit('change', [val])
	  } else {
		if (this.dropdownValues.includes(val)) {
			const index = this.dropdownValues.indexOf(val);
			if (index > -1) {
				this.dropdownValues.splice(index, 1);
			}
		} else {
			this.dropdownValues.push(val)
		}
	  }
	  if (!did_emit) {
		  this.$emit('change', this.dropdownValues)
	  }
	  
  }
}
